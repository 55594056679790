import styled from 'styled-components';
import media from '../styling/media';



const StyledFooterWrapper = styled.div`
    //background-color: #4A317C;
    background-color: #511759;
    color: #fff;

.hc-footer {
    text-align: center;
}

img {
    padding-bottom: 15px;
    width: 200px
}

a {
    color: white;

    &:hover {            
        color: #F1C400; 
        text-decoration: none;   
    }
}

.footer-div.footer  {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.footer {
    margin: 0 auto;
}

.soc-icon {
    padding: 10px;
    font-size: 22px;
    display: inline-block;

    &:hover {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

    }

    &:last-child {
        padding-right: 0;
    }
}

.footer-div.footer .navigation-footer-branding {
    flex: 1 50%;
}

.navigation-footer-branding {
    text-align: left;
}

.footer-div.footer .media-socialLinks {
    border: none;
    display: flex;
    align-items: center;
}

.footer-div.footer .media-socialLinks-item {
    margin-right: 15px;

    &:last-child {
        margin-right: 0;
    }
}


.navigation-footer-items {
    display: block;
    text-align: center;
    margin: 0 0 20px;
    line-height: 24px;
}

.footer-div.footer.footer .navigation-footer-items {
    max-width: 1030px;
    margin: 0 auto;
    padding: 10px 0 0 0;
}

.footer-div.footer .navigation-footer-items .navigation-footer-item {
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    
    &:not(:first-child)::after{
        content: "ǀ";
        display: block;
        left: 8px;
        position: absolute;
        top: 0;
    }
}

.navigation-footer-item {
    display: inline-block;
    position: relative;

    &:not(:first-child){
    padding-left: 22px;
    }

}

.footer-div.footer .navigation-footer-copyright {
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    flex: 1 100%;
}

.navigation-footer-copyright {
    padding-bottom: 1rem;
}

.footer-div.footer .media-socialLinks-xs p {
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 15px;
    padding-top: 25px;
}

.footer-div.footer .media-socialLinks-xs {
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
}

p {
    margin: 0 0 10px;
}

.img-icon {
    width: 36px;
}


${media.mobileS`
    .container {
        padding: 0;
    }

    .footer-div.footer {
        flex-direction: column;
    }
`}

${media.tablet`

    .footer-div.footer {
        flex-direction: row;
    }
`}

`
export default StyledFooterWrapper;