import React, { useState, useEffect, useRef, Fragment, useContext } from 'react';
import { Nav, InputGroup, Form, Col } from 'react-bootstrap';
import Input from '../../utils/Input';
import {Redirect} from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import StyledSearchBar from '../styling/StyledSearchBar';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ContextApi from "../../ContextApi";
// import allAssetsDictionary from '../../data/allAssetsDictionary';



const SearchBar = () => {
    const { allPrograms, setAllPrograms, allTalent, setAllTalent } = useContext(ContextApi);
    const [display, setDisplay] = useState(false);
    const [setActive, setActiveState] = useState('');
    const [setWidth, setWidthState] = useState('0px')
    const [search, setSearch] = useState('');
    const content = useRef(null);
    const wrapperRef = useRef(null);
    const history = createBrowserHistory({forceRefresh:true});

    useEffect(() => {

        const fetchPrograms = async () => {
        const res = await axiosInstance
  
          .get(`/SearchPrograms`)
          .then((res) => {
            setAllPrograms(res.data)
            console.log(res.data)
          })
          .catch(err => console.log(err));
        return res;
      };
      
      fetchPrograms();

      const fetchTalent = async () => {
          const res = await axiosInstance

          .get(`/SearchTalent`)
          .then((res) => {

            var dublicates = [];
            var sortedData = [];
            let resData = res.data;

            resData.forEach(element => {
                //console.log(element);

                if(dublicates.includes(element.talent)){
                    console.log("");
                    }

                else{
                    dublicates.push(element.talent);
                    sortedData.push(element);
                }

                
            });

            
            setAllTalent(sortedData);
          })
          .catch(err => console.log(err));
          return res;
      };

      fetchTalent();
    }, []);  

    const handleChange = event => {
        setSearch(event.target.value);
        setDisplay(true);
        if (event.target.value.length === 0) {
            setDisplay(false)
        }
    }

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            setDisplay(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClick = (selectedAsset) => {
        //let channel = searchedChannel(selectedAsset.assetType);  // '/hallmark-channel'
        //let path = searchedPath(selectedAsset.assetType);  // "/tve-vod/tve-marketing-assets/logos"
        //let urlResult = channel + path;
    
        setSearch('');
        //setSearch('');
        setDisplay(false);
        
        var selectedAssetSplit = selectedAsset.split(" (");

        if(selectedAssetSplit.length === 2){
            var programTitle = selectedAssetSplit[0];
            var channel = selectedAssetSplit[1].slice(0,-1); 
        }

        //history.push(urlResult);
        history.push(`/hallmark-channel/programming/program=${programTitle}&channel=${channel}`);

        
    }

    const handleTalent = (selectedTalent) => {
        setSearch('');
        setDisplay(false);
        history.push(`/talentSearchProgramming/talent=${selectedTalent}`);
    }

    // const searchedChannel = assetType => {
    //     let channelInitials = assetType.substring(0,2);

    //     if (channelInitials === 'Hc') {
    //         return '/hallmark-channel'
    //     } else if (channelInitials === 'Mm') {
    //         return '/hallmark-movies-mysteries'
    //     } else if (channelInitials === 'Dr') {
    //         return '/hallmark-drama'
    //     }
    // }

    const searchedPath = assetType => {
        let assetPath = assetType.substring(3);

        // return allAssetsDictionary.filter(asset => asset.section === assetPath).map(result => result.url)[0];
    }

    function toggleAccordion() {
        setActiveState(setActive === '' ? 'active' : '');
        setWidthState(setActive === 'active' ? '0px' : `${content.current.scrollWidth}px`);
    }


    return (
        <Fragment>
            <StyledSearchBar ref={wrapperRef} className='accordion__section'>
                <Nav.Link className={`accordion ${setActive}`} onClick={toggleAccordion}>
                    {setActive === 'active' ? null :
                        <i className="fas fa-search accordion__title"></i>
                    }
                </Nav.Link>

                <div ref={content} style={{ width: `${setWidth}` }} className='accordion__content'>
                    <Form.Group as={Col} mb={0} className="form-group-content">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id='inputGroupPrepend'><i className="fas fa-search"></i></InputGroup.Text>
                                <Input
                                    results='0'
                                    id='search'
                                    inputtype='search'
                                    name='search'
                                    className='search'
                                    placeholder="Search"
                                    value={search}
                                    onChange={handleChange}
                                />
                                <Nav.Link className='underline close-btn' onClick={toggleAccordion}>
                                    <i className="fas fa-times"></i>
                                </Nav.Link>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                    {display && (
                        <div className='searchContainer' id='search-style'>
                            {
                           allPrograms
                           .filter(value => {
                               const asset = value.toLowerCase();
                               if (asset.indexOf(search.toLowerCase()) !== -1) {
                                   return asset;
                               }})
                           .map(value => {
                               return (
                                   <div className='option' key={value} onClick={() => handleClick(value)} >
                                       <span>{value}</span>
                                   </div>
                               )
                           })
                            }
                            {
                           allTalent
                           .filter(value => {
                            const asset = value.talent.toLowerCase();
                            if (asset.indexOf(search.toLowerCase()) !== -1) {
                                return asset;
                            }})
                           .map(value => {
                               return (
                                   <div className='option' key={value} onClick = {() => handleTalent(value.talent)} >
                                       <span>{value.talent}</span>
                                   </div>
                               )
                           })
                            }
                        </div>
                    )}

                </div>
            </StyledSearchBar>
        </Fragment>
    )
};

export default SearchBar;